import './App.scss'
import logo from './images/logo.png';

function App() {
  return (
    <main>
      <div class="stars1" aria-hidden="true"></div>
      <div class="stars2" aria-hidden="true"></div>
      <div class="stars3" aria-hidden="true"></div>
      <div class="page-wrapper">
        <div class="page-content">
          <div class="card">
            <div class="content">
              <h2 class="title">About</h2>
              <div>
                <p class="copy">Przemyslaw Krysciak</p>
                <img src={logo} alt="procoding.de" />
                <p class="copy text">Developer</p>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="content">
              <h2 class="title">Work</h2>
              <div>
                <p class="copy">Latori GmbH</p>
                <svg class="logo transition-all h-8" viewBox="0 0 178.2 153.344">
                  <g>
                    <path d="M70.393,13.54l-57.133,97.6H120.378Z" transform="translate(19.536 19.948)" fill="none"></path>
                    <path d="M85.439,30.1h0l1.187-1.385,46.25,90.3L95.95,0H75.323L2.46,126.533Z" transform="translate(3.624)" fill="#5ac827"></path>
                    <path d="M78.207,4.19l37.915,123.194H18.65l110.531,17.758,12.416,1.929,9.126-15.977Z" transform="translate(27.477 6.173)" fill="#006837"></path>
                    <path d="M82.039,17,0,112.345,9.671,128.3H153.64l-125-20.083Z" transform="translate(0 25.046)" fill="#8cc63f"></path>
                  </g>
                </svg>
              </div>
              <a href="https://www.latori.com/" target="_blank" class="btn" rel="noreferrer">Homepage</a>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default App;
